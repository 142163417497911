import React from "react"
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

import "../styles/style.scss"

/**
 * 
 * @returns 
 * 



 */

export default function CookiePolicy() {
  return (
    <>
      <Seo />
      <Header />
      <main className="page">
        <div className="page--container container">
          <h1 className="text-heading">Cookie policy</h1>
          <p>La presente Cookie Policy riguarda l’utilizzo dei cookie da parte del sito web apprendo.ai. Per la Privacy Policy del sito web può cliccare qui.</p>
          <br />
          <h2 className="text-notehead">1.	Chi è il Titolare del trattamento?</h2>
          <p>

            Il titolare del trattamento è la società APPRENDO S.r.l. con sede in 33100 Udine, via Marinoni n. 12 (P.I. 03029040304).
            Il Titolare è contattabile all’indirizzo email{" "}
            <strong>support@apprendo.ai</strong>
          </p>
          <div className="page--contents">
            <section className="page--content">
              <h2 className="text-notehead">
                2.	Che cos’è un cookie?
              </h2>
              <p>
                Un cookie è un piccolo file che viene inviato e registrato nel dispositivo dell’utente (PC/smartphone/tablet),
                quando accede al nostro sito internet, per essere poi ritrasmesso al nostro sito alla successiva visita.
                I cookie vengono utilizzati per diverse finalità, ad esempio:
                <ul>
                  <li>ricordare le azioni e preferenze dell’utente (come, ad esempio, i dati di login, la lingua prescelta, le dimensioni dei caratteri, ecc.) affinché non debba essere ripetuta la scelta di tale preferenza quando l’utente visita nuovamente il sito o naviga da una pagina all’altra dello stesso.</li>
                  <li>eseguire autenticazioni informatiche, monitoraggio di sessioni e memorizzazione di informazioni riguardanti le attività degli utenti che accedono ad un sito, </li>
                  <li>tenere traccia della navigazione dell’utente all’interno del sito stesso per finalità statistiche o pubblicitarie attraverso un codice identificativo unico contenuto nel cookie.</li>
                </ul>

                È possibile fornire una tassonomia dei cookie in base ad alcune caratteristiche:

                <ul>
                  <li>CICLO DI VITA: di sessione se scadono alla chiusura del browser, o permanenti se restano fino al decorrere di un termine, anche di anni;</li>
                  <li>UTILIZZO / FINALITÀ: tecnici (necessari, talvolta indispensabili, per una completa – o migliore – fruizione del sito), analitici (utilizzati per elaborare analisi statistiche sulle modalità di navigazione degli utenti e raccolgono informazioni anonime sull’utilizzo del sito web (ad es. Google Analitycs) o di marketing e profilazione (finalizzati a creare un profilo dell’utente, per poi inviargli messaggi pubblicitari in linea con le preferenze manifestate dallo stesso nel corso della precedente navigazione);</li>
                  <li>PROVENIENZA: di prima parte (si tratta di cookie istallati direttamente dal titolare attraverso il sito web) o di terze parti (si tratta di cookie istallati da un sito o da un webserver diverso dal nostro sito che l’utente sta visitando).</li>
                </ul>
                Alcune operazioni non potrebbero essere compiute senza l’uso dei cookie, che in certi casi sono quindi tecnicamente necessari per lo stesso funzionamento del sito. Altre tipologie di cookie e tecnologie analoghe (come ad esempio il pixel di Facebook) possono essere invece usate per tracciare la navigazione degli utenti e profilare interessi e abitudini, per visualizzare annunci pubblicitari mirati.

                Per ulteriori informazioni sui tipi di cookie e sul loro funzionamento è possibile consultare i seguenti siti:
                <ul>
                  <li><a>www.youronlinechoices.com</a></li>
                  <li><a>http://www.allaboutcookies.org</a></li>
                  <li><a>https://www.garanteprivacy.it/temi/cookie;</a></li>
                </ul>

                L’elenco completo dei cookies presenti sul sito web <a>apprendo.ai</a> è inserito nella tabella riportata in calce alla presente informativa.
              </p>

            </section>
            <section className="page--content">
              <h2 className="text-notehead">
                3. Come modificare le impostazioni relative ai cookie?
              </h2>
              Il nostro sito web può utilizzare cookies tecnici, statistici e di marketing e profilazione.
              L’utente può scegliere se cancellare, o bloccare, taluni (o tutti) i cookie attraverso l’apposito banner presente sul sito o tramite la configurazione del proprio browser. La procedura di configurazione dei cookies è diversa per ciascun browser, di seguito riportiamo il link alle istruzioni dei browser più diffusi:

              <ul>
                <li><a href="https://support.google.com/chrome/answer/95647">Google Chrome</a></li>
                <li><a href="https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop">Mozilla Firefox</a></li>
                <li><a href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</a></li>
                <li><a href="https://support.microsoft.com/it-it/microsoft-edge/informazioni-sulla-prevenzione-del-monitoraggio-in-microsoft-edge-5ac125e8-9b90-8d59-fa2c-7f2e9a44d869">Microsoft Edge</a></li>
                <li><a href="https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d#:~:text=In%20Internet%20Explorer%20selezionare%20il,proprietari%20o%20di%20terze%20parti.">Microsoft Explorer</a></li>
                <li><a href="https://support.apple.com/it-it/HT201265">Apple Safari</a></li>
              </ul>

              Ricordiamo inoltre, che disabilitando completamente i cookie nel browser, l’utente potrebbe non essere in grado di utilizzare tutte le funzionalità interattive.
            </section>
            <section className="page--content">
              <h2 className="text-notehead">
                4. Quali cookie utilizziamo nel nostro sito?
              </h2>
              <h3 className="text-notehead">
                Cookie tecnici
              </h3>
              <p>
                I cookie tecnici sono quelli che il Titolare utilizza al solo fine di consentire all'utente la normale navigazione e fruizione del sito web e una migliore esperienza del servizio offerto. Possono essere suddivisi in cookie di navigazione (garantiscono la normale navigazione e fruizione del Sito) e cookie funzionali (permettono all'utente la navigazione in funzione di una serie di criteri selezionati come la lingua o il paese di provenienza) al fine di migliorare il servizio reso sul Sito. Sul presente sito vengono utilizzati cookie tecnici per memorizzare la decisione dell’Utente sull’utilizzo dei cookie sul sito web.
              </p>
              <table>
                <thead>
                  <tr>
                    <th width="20%">NOME COOKIE</th>
                    <th width="20%">FORNITORE</th>
                    <th width="40%">FUNZIONE</th>
                    <th width="20%">SCADENZA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>KEYCLOAK_3P_COOKIE_SAMESITE</th>
                    <th>Auth.viblio.com</th>
                    <th>Utilizzato per la funzione di accesso al sito web.</th>
                    <th>1 giorno</th>
                  </tr>
                </tbody>
              </table>

              <h3 className="text-notehead">
                Cookie statistici
              </h3>
              <p>
                Questi cookie vengono installati da soggetti terzi rispetto al titolare del trattamento. I cookie (ad es. Google Analitycs) vengono utilizzati per raccogliere informazioni, in forma aggregata o non aggregata, relative al numero di utenti e su come venga utilizzato il sito web (numero di pagine visitate, pagine accedute, ecc.) al fine di elaborare analisi statistiche sulle modalità di navigazione degli utenti.
              </p>
              <table>
                <thead>
                  <tr>
                    <th width="20%">NOME COOKIE</th>
                    <th width="20%">FORNITORE</th>
                    <th width="40%">FUNZIONE</th>
                    <th width="20%">SCADENZA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>ugid</th>
                    <th>Unsplash.com</th>
                    <th>Utilizzato per l’implementazione di contenuti video sul sito web.</th>
                    <th>1 anno</th>
                  </tr>
                </tbody>
              </table>
              <h3 className="text-notehead">
                Cookie di terze parti
              </h3>
              <p>
                Questi cookie vengono installati da soggetti terzi rispetto al titolare del trattamento per tracciare l’attività dell’utente nel sito web e possono essere impostati solo dopo che quest’ultimo ha dato il suo consenso.
              </p>
              <table>
                <thead>
                  <tr>
                    <th width="20%">NOME COOKIE</th>
                    <th width="20%">FORNITORE</th>
                    <th width="20%">SCADENZA</th>
                    <th width="40%">FUNZIONE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>__insp_sid</th>
                    <th>Inspectlet</th>
                    <th>Sessione</th>
                    <th rowSpan="6">Utilizzati per analizzare il comportamento dell’utente e per raccogliere informazioni al fine di migliorare l’esperienza dell’utente.</th>
                  </tr>
                  <tr>
                    <th>__insp_ref</th>
                    <th>Inspectlet</th>
                    <th>Sessione</th>
                  </tr>
                  <tr>
                    <th>__insp_scpt</th>
                    <th>Inspectlet</th>
                    <th>Sessione</th>
                  </tr>
                  <tr>
                    <th>__insp_nv</th>
                    <th>Inspectlet</th>
                    <th>Sessione</th>
                  </tr>
                  <tr>
                    <th>__insp_wid</th>
                    <th>Inspectlet</th>
                    <th>Sessione</th>
                  </tr>
                  <tr>
                    <th>__insp_uid</th>
                    <th>Inspectlet</th>
                    <th>Sessione</th>
                  </tr>
                </tbody>
              </table>
              <h3 className="text-notehead">
                Cookie non classificati
              </h3>
              <table>
                <thead>
                  <tr>
                    <th width="20%">NOME COOKIE</th>
                    <th width="20%">FORNITORE</th>
                    <th width="40%">FUNZIONE</th>
                    <th width="20%">SCADENZA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>loglevel:webpach-dev-server</th>
                    <th>apprendo.ai</th>
                    <th>Non classificato</th>
                    <th>Persistente</th>
                  </tr>
                </tbody>
              </table>
            </section>
            <section className="page--content">
              <h2 className="text-notehead">
                5. Modifiche o aggiornamenti alla presente cookie policy
              </h2>
              <p>
                Il titolare del trattamento si riserva il diritto di apportare modifiche alla presente cookie policy in qualunque momento dandone pubblicità agli Utenti su questa pagina. Si prega dunque di consultare frequentemente questa pagina, prendendo come riferimento la data dell’ultimo aggiornamento indicata qui di seguito.
              </p>
              <p><strong>Ultimo aggiornamento 22.09.2022</strong></p>
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}
